var g = Object.defineProperty;
var c = (i, s, e) => s in i ? g(i, s, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: e
}) : i[s] = e;
var n = (i, s, e) => (c(i, typeof s != "symbol" ? s + "" : s, e), e);
import { S as r, M as d, m as h } from "./session--x2YbK5i.js";
const m = {
    allowedHosts: ["*"],
    timeout: 5e3
  },
  a = "0.0.4";
class l {
  constructor(s, e) {
    n(this, "id");
    n(this, "version");
    n(this, "session");
    n(this, "messaging");
    n(this, "timeout");
    n(this, "allowedOrigins");
    /** @namespace */
    n(this, "ui", {
      setTitle: async s => {
        await this.session.connected;
        const e = this.messaging.send("changeNavigation", {
          title: s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      setDescription: async s => {
        await this.session.connected;
        const e = this.messaging.send("changeNavigation", {
          description: s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      setBacklinkLabel: async s => {
        await this.session.connected;
        const e = this.messaging.send("changeNavigation", {
          backLabel: s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      setStatusLoading: async (s = !0) => {
        await this.session.connected;
        const e = this.messaging.send("changeStatus", {
          status: s ? "loading" : "ready"
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      setStatusSuccess: async s => {
        await this.session.connected;
        const e = this.messaging.send("changeStatus", {
          status: "success",
          ...s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      setStatusError: async s => {
        await this.session.connected;
        const e = this.messaging.send("changeStatus", {
          status: "error",
          ...s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      }
    });
    /** @namespace */
    n(this, "navigation", {
      back: async () => {
        await this.session.connected;
        const s = this.messaging.send("navigate", {
          type: "back"
        });
        await this.messaging.nextReply(s, {
          type: "done"
        });
      },
      openNewEmbed: async s => {
        await this.session.connected;
        const e = this.messaging.send("navigate", {
          type: "embed",
          url: s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      openExternal: async s => {
        await this.session.connected;
        const e = this.messaging.send("navigate", {
          type: "external",
          url: s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      /**
       * Allows registering a callback function that is invoked whenever user
       * leaves screen due to native/host back navigation.
       *
       * Registering multiple callbacks will result in only the most recently
       * registered callbvack being invoked (i.e. newer callbacks overwrite older ones)
       *
       * @param callback a function that will be called on any back navigation in host.
       *                 If the callback returns false, the host navigation will be blocked.
       *                 The response needs to be received within 100ms, or host navigation
       *                 will continue regardless of callback.
       *
       * @returns A function that will remove the navigation callback
       */
      onBackNavigation: s => this.messaging.subscribe("navigate", e => {
        e.data.payload.type === "back" && this.messaging.sendReplyTo(e, "done", {
          data: s()
        });
      }),
      enableExitConfirmation: async s => {
        await this.session.connected;
        const e = this.messaging.send("changeStatus", {
          status: "locked",
          ...s
        });
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      disableExitConfirmation: async () => {
        await this.session.connected;
        const s = this.messaging.send("changeStatus", {
          status: "ready"
        });
        await this.messaging.nextReply(s, {
          type: "done"
        });
      }
    });
    /** @namespace */
    n(this, "context", {
      /* getUser and getAddress are disabled for now due to privacy concerns.
      
          getUser: async () => {
            await this.session.connected;
            const msg = this.messaging.send('requestData', { type: 'user' });
            const response = await this.messaging.nextReply(msg, {
              type: 'requestedData',
            });
            return response.data.payload.requested as User;
          },
          getAddress: async () => {
            await this.session.connected;
            const msg = this.messaging.send('requestData', { type: 'address' });
            const response = await this.messaging.nextReply(msg, {
              type: 'requestedData',
            });
            return response.data.payload.requested as Address;
          },
          */
      getTenant: async () => {
        await this.session.connected;
        const s = this.messaging.send("requestData", {
          type: "tenant"
        });
        return (await this.messaging.nextReply(s, {
          type: "requestedData"
        })).data.payload.requested;
      },
      getIpdcProduct: async () => {
        await this.session.connected;
        const s = this.messaging.send("requestData", {
          type: "ipdc"
        });
        return (await this.messaging.nextReply(s, {
          type: "requestedData"
        })).data.payload.requested;
      }
    });
    /** @namespace */
    n(this, "appointment", {
      start: async () => (await this.session.connected, await this.navigation.enableExitConfirmation({
        message: "Door uw afspraak te annuleren, gaan alle ingevulde gegevens verloren en wordt er geen afspraak gemaakt.",
        confirm: "Afspraak annuleren",
        ignore: "Afspraak verder bewerken"
      }), {
        products: [await this.context.getIpdcProduct()]
      }),
      create: async s => {
        await this.session.connected;
        const e = this.messaging.send("createAppointment", s);
        await this.messaging.nextReply(e, {
          type: "done"
        });
      },
      cancel: async s => {
        if (await this.session.connected, !s) await this.navigation.back();else {
          const e = this.messaging.send("cancelAppointment", {
            id: s
          });
          await this.messaging.nextReply(e, {
            type: "done"
          });
        }
      }
    });
    if (typeof window > "u") throw new Error("Client can only run in a browser environment");
    const t = Object.assign({}, m, e || {});
    this.id = s, this.version = a, this.session = new r("burgerprofiel-embed-client", this.id), this.messaging = new d(this.session, window.top || window.parent || window, window), this.timeout = t.timeout, this.allowedOrigins = [window.origin, ...(t.allowedHosts || [])];
  }
  // TODO: add destroy method
  // TODO: add disconnect method
  async connect() {
    console.log(`${this.session.logPrefix} Starting connection (v${a})`);
    const s = this.messaging.send("connect", {
        id: this.id,
        version: a
      }),
      e = await this.messaging.nextReply(s, {
        type: "connected",
        timeout: this.timeout
      });
    if (this.allowedOrigins.some(t => h(t, e.origin))) this.session.onConnection(e), this.messaging.onConnection(e), console.log(`${this.session.logPrefix} Connection established:`, event);else {
      const t = "Host origin is not allowed: " + e.origin;
      this.messaging.send("error", {
        message: t
      }), this.session.error(t), console.error(`${this.session.logPrefix} ${t}`);
    }
    this.messaging.subscribe(t => {
      if (t.data.action === "error") {
        const o = t.data.payload;
        console.error(`${this.session.logPrefix} Error from host:`, o.message, o.data);
      } else console.debug(`${this.session.logPrefix} Message from host:`, t.data.action, t.data.payload);
    });
  }
}
const w = "0.0.4";
function u(i, s) {
  return new l(i, s);
}
export { u as createMbpEmbedClient, w as version };
