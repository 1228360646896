var L = Object.defineProperty;
var k = (e, s, t) => s in e ? L(e, s, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: t
}) : e[s] = t;
var w = (e, s, t) => (k(e, typeof s != "symbol" ? s + "" : s, t), t),
  E = (e, s, t) => {
    if (!s.has(e)) throw TypeError("Cannot " + t);
  };
var o = (e, s, t) => (E(e, s, "read from private field"), t ? t.call(e) : s.get(e)),
  a = (e, s, t) => {
    if (s.has(e)) throw TypeError("Cannot add the same private member more than once");
    s instanceof WeakSet ? s.add(e) : s.set(e, t);
  },
  n = (e, s, t, i) => (E(e, s, "write to private field"), i ? i.call(e, t) : s.set(e, t), t);
var O = (e, s, t, i) => ({
    set _(r) {
      n(e, s, r, t);
    },
    get _() {
      return o(e, s, i);
    }
  }),
  l = (e, s, t) => (E(e, s, "access private method"), t);
function A(e, s) {
  return !e || !s ? !1 : new RegExp(e.replace(/([.?+^$[\]\\(){}|/-])/g, "\\$1").replace(/\*/g, ".*")).test(s);
}
function F() {
  return Math.random().toString(36).slice(2);
}
var m, f, M, b, T;
class q {
  constructor(s, t, i) {
    a(this, f);
    a(this, b);
    w(this, "subscribers");
    w(this, "targetOrigin", "*");
    // Needs to be arrow function to preserve `this` context as event handler
    a(this, m, s => {
      s.data.type === "burgerprofiel-embed-msg" && A(this.targetOrigin, s.origin) && (s.data.action !== "connect" && s.data.sessionId !== this.session.id || this.subscribers.forEach(t => t(s)));
    });
    this.session = s, this.outgoing = t, this.incoming = i, this.subscribers = /* @__PURE__ */new Set(), this.incoming.addEventListener("message", o(this, m));
  }
  destroy() {
    this.incoming.removeEventListener("message", o(this, m)), this.subscribers.clear();
  }
  onConnection(s) {
    this.targetOrigin = s.origin, s.source && (this.outgoing = s.source);
  }
  send(s, ...t) {
    const i = this.session.nextMessageId;
    return this.outgoing.postMessage({
      action: s,
      payload: t[0],
      messageId: i,
      sessionId: this.session.id,
      type: "burgerprofiel-embed-msg",
      source: this.session.source
    }, this.targetOrigin), i;
  }
  sendReplyTo(s, t, ...i) {
    const r = s.source || this.outgoing,
      c = this.session.nextMessageId;
    return r.postMessage({
      action: t,
      payload: i[0],
      messageId: c,
      replyTo: s.data.messageId,
      sessionId: s.data.sessionId,
      type: "burgerprofiel-embed-msg",
      source: this.session.source
    }, this.targetOrigin || s.origin), c;
  }
  subscribe(...s) {
    if (s.length === 1) return l(this, f, M).call(this, s[0]);
    const [t, i] = s;
    return l(this, f, M).call(this, r => {
      if (r.data.action === t) return i(r);
    });
  }
  async next(s, {
    timeout: t = 3e4
  } = {}) {
    return l(this, b, T).call(this, {
      condition: i => i.data.action === s,
      timeout: t,
      timeoutMessage: `Timeout waiting for next message of type ${String(s)}`
    });
  }
  async nextReply(s, {
    type: t,
    timeout: i = 3e4
  } = {}) {
    return l(this, b, T).call(this, {
      condition: r => r.data.replyTo === s,
      checkAssertions: r => {
        if (r.data.action === "error") {
          const c = r.data.payload;
          throw new Error(`Received error in reply to ${s}: ${c.message}`);
        } else if (t && r.data.action !== t) {
          const c = String(t),
            I = String(r.data.action);
          throw new Error(`Expected reply of type ${c} to ${s} but got ${I}`);
        }
      },
      timeout: i,
      timeoutMessage: `Timeout waiting for reply${t ? " of type " + String(t) : ""} to message #${s}`
    });
  }
}
m = new WeakMap(), f = new WeakSet(), M = function (s) {
  return this.subscribers.add(s), () => {
    this.subscribers.delete(s);
  };
}, b = new WeakSet(), T = async function ({
  condition: s = () => !0,
  checkAssertions: t = () => {},
  timeout: i = 3e4,
  timeoutMessage: r = "Timeout waiting for next message"
} = {}) {
  return new Promise((c, I) => {
    const C = this.subscribe(S => {
        if (s(S)) try {
          t(S), c(S);
        } catch (P) {
          I(P);
        } finally {
          C(), clearTimeout(R);
        }
      }),
      R = setTimeout(() => {
        C(), I(`${r} (waited ${i}ms)`);
      }, i);
  });
};
var u, g, d, p, h, y, $, x;
class z {
  constructor(s, t) {
    a(this, u, void 0);
    a(this, g, void 0);
    a(this, d, "pending");
    a(this, p, 0);
    a(this, h, void 0);
    a(this, y, void 0);
    a(this, $, void 0);
    a(this, x, void 0);
    this.source = s, n(this, g, t), n(this, x, s), s === "burgerprofiel-embed-client" ? n(this, u, F()) : (n(this, u, "unconnected"), n(this, p, 1e9)), n(this, h, this.resetConnection());
  }
  get id() {
    return o(this, u);
  }
  get clientId() {
    return o(this, g);
  }
  get status() {
    return o(this, d);
  }
  get nextMessageId() {
    return ++O(this, p)._;
  }
  get connected() {
    return o(this, h);
  }
  get logPrefix() {
    return o(this, x) === "burgerprofiel-embed-client" ? `[client][${this.id}]` : `[ host ][${this.id}]`;
  }
  setStatus(s, t) {
    var i, r;
    s !== o(this, d) && (o(this, d) === "pending" ? s === "connected" ? (i = o(this, y)) == null || i.call(this) : (r = o(this, $)) == null || r.call(this, t) : n(this, h, this.resetConnection()), n(this, d, s));
  }
  resetConnection() {
    return new Promise((s, t) => {
      n(this, y, s), n(this, $, t);
    });
  }
  onConnection(s) {
    s.data.action === "connect" && (n(this, u, s.data.sessionId), n(this, g, s.data.payload.id)), this.setStatus("connected");
  }
  error(s) {
    this.setStatus("failed", s);
  }
}
u = new WeakMap(), g = new WeakMap(), d = new WeakMap(), p = new WeakMap(), h = new WeakMap(), y = new WeakMap(), $ = new WeakMap(), x = new WeakMap();
export { q as M, z as S, A as m };
